<template>
    <div
        class="productionMap logisticsStationMap"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <logisticsMap :map-data="mapData" ref="ref_logisticsMap"></logisticsMap>
        <!--        操作区域-->
        <div class="hand-area">
            <div class="search-box">
                <span class="iconfont iconweizhi"></span>
                <span class="iconfont iconjiaobancheguobangchaxun"></span>
                <el-select
                    v-model="stationId"
                    placeholder="请选择"
                    @change="changeStation"
                >
                    <el-option
                        v-for="item in allStationData"
                        :key="item.station_id"
                        :label="item.station_name"
                        :value="item.station_id"
                    >
                    </el-option>
                </el-select>
                <el-input class="search-input" placeholder="工程名称/工程地址/车号/车牌号" v-model="searchVal">
                    <el-button slot="append" icon="el-icon-search" @click="getLocation"></el-button>
                </el-input>
            </div>
            <div class="btns right">
                <!--                <el-button type="primary" class="search iconfont iconsousuo1" v-popover:searchPopover></el-button>-->
                <el-button type="primary" class="setUp iconfont iconshezhi" v-popover:setUpPopover></el-button>
                <el-button type="primary" class="route iconfont iconhangxian"></el-button>
                <!--                <el-button type="primary" class="siteVehicles iconfont icongengduo1" v-popover:siteVehiclesPopover></el-button>-->
            </div>
        </div>
        <!-- 设置 -->
        <el-popover
            trigger="click"
            ref="setUpPopover"
            v-model="setUpVisible"
            placement="bottom-end"
            popper-class="mapPopover setUpPopover"
        >
            <h3>定位频率设置</h3>
            <el-input placeholder="请输入内容" v-model="setWholeTime">
                <template slot="append">
                    秒
                </template>
            </el-input>
            <div class="setUpPopoverBtns">
                <el-button size="mini" type="text" @click="setUpVisible = false">
                    取消
                </el-button>
                <el-button type="primary" size="mini" @click="setCarInterval(0)">
                    确定
                </el-button>
            </div>
        </el-popover>
        <!--        <el-popover-->
        <!--            trigger="click"-->
        <!--            ref="siteVehiclesPopover"-->
        <!--            placement="bottom-end"-->
        <!--            popper-class="mapPopover siteVehiclesPopover"-->
        <!--        >-->
        <!--            <siteVehicles-->
        <!--                ref="ref_siteVehicles"-->
        <!--                :projects-address="projectsAddress"-->
        <!--                :car-statistics="carStatistics"-->
        <!--                :anomaly-statistics="anomalyStatistics"-->
        <!--                @mapDataLoading="mapDataLoading"-->
        <!--                @gitStationVehicles="gitStationVehicles"-->
        <!--            ></siteVehicles>-->
        <!--        </el-popover>-->
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
import largeScreenFun from '../../JS';
import logisticsMap from './logisticsMap.vue';
// import siteVehicles from './siteVehicles.vue';
export default {
    name: 'production-map',
    components: {
        logisticsMap,
    },
    props: {},
    data() {
        return {
            loading: false,
            time: '',
            stationId: '',
            stationData: [],
            // 所有场站数据
            allStationData: [],
            // 所有场站位置
            allStationLocation: [],
            tenantId: '',
            // 搜索
            searchVisible: false,
            searchVal: '',
            // 定位频率设置
            setUpVisible: false,
            setWholeTime: 0,

            // 在施工地
            projectsAddress: [],
            // 运输车辆
            carStatistics: [],
            // 异常监控
            anomalyStatistics: [],

            // 地图数据
            mapData: {
                // 车辆数据
                vehicleData: [],
                // 场站数据
                stationLocation: [],
                // 工地数据
                siteLocation: [],
            },

            // 车辆状态变更-消息
            carStatus: {},
            // 车辆定位变更-消息
            carPosition: {},
            showAirLine: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {
        async resize() {
            if (this.$refs.ref_logisticsMap.mapChart) {
                this.$refs.ref_logisticsMap.mapChart.resize();
            }
        },
        async initPage() {
            this.time = this.$parent.time;
            this.allStationData = this.$parent.allStationData;
            this.stationId = this.allStationData[0].station_id;
            this.tenantId = this.allStationData[0].tenantId;
            // 获取场站位置
            this.allStationLocation = await largeScreenFun.getStationLocation(this.tenantId) || [];
            this.mapData.stationLocation = this.allStationLocation;
            this.loadPage();
        },
        async loadPage() {
            this.loading = true;
            // 场站为空 获取所有场站数据  循环获取所有场站下数据
            await this.gitStationVehicles();
            this.$refs.ref_logisticsMap.handleMapData();
            this.$refs.ref_logisticsMap.initChart();
            this.loading = false;
        },
        // 更新地图数据
        async updateMapData() {
            await this.gitStationVehicles();
            this.$refs.ref_logisticsMap.handleMapData();
            this.$refs.ref_logisticsMap.initChart();
        },
        // 切换场站
        changeStation(station_id) {
            const selectItem = this.allStationData.filter(item => item.station_id === station_id)[0];
            this.tenantId = selectItem.tenantId;
            this.stationId = selectItem.station_id;
            if (selectItem.station_id) {
                this.mapData.stationLocation = [this.allStationLocation.filter(item => item.station_id === station_id)[0]];
            } else {
                this.mapData.stationLocation = this.allStationLocation;
            }
            this.loadPage();
        },
        // 获取场站、车辆数据
        async gitStationVehicles() {
            // 获取车辆位置
            const carStatistics = await largeScreenFun.getCarLocationData( this.tenantId, this.stationId, this.searchVal) || [];
            // 获取工地位置
            const siteLocation = await largeScreenFun.getConstructionSiteData(this.time, this.tenantId, this.stationId) || [];

            this.mapData.vehicleData = carStatistics;
            this.mapData.siteLocation = siteLocation;
        },

        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${status}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.setUpVisible = false;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },

        // 搜索
        async getLocation() {
            this.searchVisible = false;
            await this.loadPage();
        },

        // 在施工地、运输车辆选择回调
        mapDataLoading(row) {
            if (row.type === 'siteLoading') {
                this.mapData.stationData = row.data;
            } else if (row.type === 'vehicleLoading') {
                this.mapData.vehicleData = row.data;
            }
        },
        // 验证是否是当前租户和场站下的数据
        validData(tenant_id, mixstation_code) {
            const userTenantId = this.$takeTokenParameters('TenantId');
            const curMixStationCode = this.stationId;
            if (tenant_id === userTenantId && mixstation_code === curMixStationCode) {
                return true;
            }
            return false;
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('Monitoring');
        eventBus.$off('CoordinateMessage');
    },
};
</script>
<style lang="stylus">
.mapPopover
    background: #132F55;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5), 0px 2px 6px 0px #000000;
    border-radius: 2px;
    border-color rgba(44, 72, 109, 1)
    .popper__arrow
        border-bottom-color rgba(44, 72, 109, 1) !important
        &:after
            border-bottom-color #132F55 !important
    .el-input__inner
        height 0.34rem
        line-height 0.34rem
        border 1px solid #223C61
        background-color #0F2647
    .el-input-group__append
        border 1px solid #223C61
        background-color #0F2647
.setUpPopover
    h3
        font-size 0.16rem
        margin-bottom 0.1rem
        color #fff
    .setUpPopoverBtns
        text-align right
        margin-top 0.1rem
.siteVehiclesPopover
    width 4rem !important
    padding: 0
    .siteVehicles
        .siteVehiclesNav
            width 100%
            display flex
            li
                flex 1
                height 0.32rem
                font-size 0.12rem
                line-height 0.32rem
                text-align center
                background #354D6C
                cursor pointer
                margin-right 1px
                &:last-child
                    margin-right 0
                &.active
                    background #1890FF
                color #fff
                span
                    font-size 0.14rem
        .vehicleData
            padding 0.1rem
            .classification
                display flex
                li
                    flex 1
                    height 0.56rem
                    margin-right 0.1rem
                    text-align center
                    background #284367
                    cursor pointer
                    h3
                        font-size 0.24rem
                        font-family technology
                    p
                        font-size 0.12rem
                        color #fff
                    &:last-child
                        margin-right 0
                    &:nth-of-type(1)
                        h3
                            color #1897FF
                    &:nth-of-type(2)
                        h3
                            color #F98C47
                    &:nth-of-type(3)
                        h3
                            color #0048B8
                    &:nth-of-type(4)
                        h3
                            color #FF6E77
                    &.active
                        background #132F55
                        box-shadow 0px 0px 16px inset #15BC64
                        border 1px solid #15BC64
                        h3
                            color #15BC64
            .el-carousel
                .el-carousel__indicators
                    bottom 0
                    .el-carousel__indicator
                        padding 0.1rem 0.04rem 0 0.04rem
        .vehicleList
            h4
                line-height 0.4rem
                background #1890FF
                font-size 0.18rem
                color #fff
                text-align center
            .input-with-select
                padding 0.1rem
                background #132F55
                .el-input__inner
                    height 0.3rem
                    line-height 0.3rem
                    border 1px solid #223C61
                    background-color #0F2647
                .el-input-group__append
                    border 1px solid #223C61
                    background-color #0F2647
            .vehicleTable
                max-height 50vh
                overflow-x hidden
                overflow-y auto
                table
                    width 100%
                    border-collapse collapse
                th,td
                    padding 0
                    height 0.4rem
                    text-align center
                    font-size 0.14rem
                thead
                    th
                        border none
                        background #2C486D
                        color #fff
                        font-weight 200
                tbody
                    tr
                        border 1px solid #435E86
                    td
                        background #132F55
                        color #fff
                        &:first-child
                            color #15BC64
                        &:last-child
                            color #18B6FF
            .abnormal-main
                height calc(100% - 1.38rem)
                width 100%
                .el-input__inner
                    background-color #314663
                    border 1px solid #314663
                    color #fff
                    &::-webkit-input-placeholder
                        font-size .14rem
                    &::-moz-placeholder
                        font-size .14rem
                    &::-ms-input-placeholder
                        font-size .14rem
                .abnormal-list
                    height calc(100% - .46rem)
                    overflow-y auto
                    ul
                        margin-bottom .1rem
                    li
                        display flex
                        justify-content space-between
                        padding .08rem
                        border 1px solid #50709F
                        font-size .14rem
                        color #fff
                        .plate-small-num
                            width .36rem
                            height .24rem
                            display inline-block
                            line-height .24rem
                            text-align center
                            background #FF7E00
                            font-size .14rem
                            color #fff
                            margin-right .1rem
                        .time
                            display flex
                            margin-top .03rem
                            .dot
                                display inline-block
                                width .06rem
                                height .06rem
                                background #FFFFFF
                                border-radius 50%
                                margin .05rem .1rem 0 0
                .el-pagination
                    .el-pagination__total
                        color #82A7DF
</style>
